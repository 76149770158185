import type { Player as ITF1Player, Events as PlayerEvents } from '@etf1-interne/player'

import { useEffect, useCallback, useMemo } from 'react'
import { CTA_PLAYER_MAPPING, CLICK } from '../constants/playerEvents'

/**
 *
 * @description Manage CTA player events with automatic TMS hits per event
 */
export function usePlayerCTATMSHits({
  player,
  isPlayerInitialized,
  logEvent,
}: {
  player: ITF1Player
  isPlayerInitialized: boolean
  logEvent: (name: string, evt: { value: string; event?: any }, isClickEvent: boolean) => void
}) {
  // Get all cta player events
  const playerEvents = Object.keys(CTA_PLAYER_MAPPING)

  // Create callback for each player event
  // This is a memoized function to avoid creating a new function on each render
  // { [event: string]: () => logEvent(name: string, evt: { value: string, event?: IEventParser }, isClickEvent: true)
  const eventsCallback = useMemo(
    () =>
      playerEvents.reduce((acc, playerEvent) => {
        return {
          ...acc,
          [playerEvent]: () => {
            // Add ad values to the click event if available
            const adValues = Object.entries(window?.tc_vars || {}).reduce((acc, [key, value]) => {
              if (key.startsWith('ad_')) {
                acc[key] = value
              }
              return acc
            }, {})

            logEvent(
              CLICK,
              {
                value: CTA_PLAYER_MAPPING[playerEvent],
                event: adValues,
              },
              true,
            )
          },
        }
      }, {} as Record<PlayerEvents, () => void>),
    [playerEvents, logEvent],
  )

  const setupListeners = useCallback(() => {
    // Add event listener for each player event
    playerEvents.forEach((event) => {
      player.current?.on?.(event, eventsCallback[event])
    })
  }, [eventsCallback])

  const destroyListeners = useCallback(() => {
    playerEvents.forEach((event) => {
      player.current?.off?.(event, eventsCallback[event])
    })
  }, [eventsCallback])

  useEffect(() => {
    if (isPlayerInitialized) setupListeners()
    return () => {
      if (isPlayerInitialized) destroyListeners()
    }
  }, [isPlayerInitialized, setupListeners, destroyListeners])
}
