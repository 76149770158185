import type {
  Player as ITF1Player,
  Config as PlayerConfig,
  Events as PlayerEvents,
} from '@etf1-interne/player'
import type { ReactElement, ReactNode } from 'react'
import TF1Player from '@etf1-interne/player'
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
  useState,
} from 'react'
import {
  ACTIONS_MAPPING,
  AUTOPLAY_FAILED,
  COMINGNEXT_NEXT_CLICK,
  END,
  EVENTS_MAPPING,
  JWT_EXPIRED,
  PAUSE,
  PLAY_ON_MEDIA_CLICK,
  PLAY,
  PLAYING,
  QUARTILE,
  QUARTILES,
  SEEK,
  SEEKED,
  START,
  TMS_PLAYALONG_AUTO,
  TMS_PLAYALONG_FALSE,
  TMS_PLAYALONG_MANUAL,
  TMS_SEEK_BACKWARD,
  TMS_SEEK_FORWARD,
  TMS_BUTTON_MUTE,
  TMS_BUTTON_UNMUTE,
} from '../../../constants/playerEvents'
import { CLICK } from '../../../constants/tms'
import { MAX_RETRY_ON_AUTOPLAY_FAILURE } from '../../../constants/player'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { usePlayerAdsTMSHits } from '../../../hook/usePlayerAdsTMSHits'
import { usePlayerCTATMSHits } from '../../../hook/usePlayerCTATMSHits'
import { usePlayerMediaInfoListener } from '../../../hook/usePlayerMediaInfoListener'
import { useUser } from '../../../hook/useUser'
import {
  getPlayerConfig,
  getAllPlayerListeners,
  defaultListenerCallback,
  getPlayerPosition,
} from '../../../helpers/player'
import { isIOS } from '../../../helpers/client'
import { propertiesToString } from '../../../helpers/propertiesToString'
import { removeEmptyProperties } from '../../../helpers/removeEmptyProperties'
import { useBeforeOnLoad } from '../../../hook/useBeforeOnLoad'

type ValueOf<T> = T[keyof T]
export interface IPlayer {
  playerId: string
  videoId: string | number
  children?: ReactNode | ReactElement
  autoplay?: boolean
  autoplayMuted?: boolean
  noAds?: boolean
  consent: {
    consentString?: string
    consentPolicyState?: number
  }
  hasComingNext?: boolean
  onPlayerReady?: () => void
  onPlayerInit?: () => void
  onVideoChange?: () => void
  onMediaPlay?: () => void
  onMediaEnd?: () => void
  mediaInfoParams?: { [key: string]: string | number }
  syndication?: boolean
  introductionVideoId?: string
  liveTimeShift?: number
  disabledOptionsButton?: boolean
}

interface PlayerEventData {
  value?: number | string
  currentQuartile?: number
  event?: any
}

const TIME_BETWEEN_UPDATES = 1000 // 1 second

function PlayerComponent(
  {
    playerId,
    videoId,
    children,
    consent,
    autoplay,
    autoplayMuted,
    noAds,
    hasComingNext,
    onPlayerReady,
    onPlayerInit,
    onVideoChange,
    onMediaEnd,
    onMediaPlay,
    mediaInfoParams,
    syndication,
    introductionVideoId,
    liveTimeShift,
    disabledOptionsButton,
  }: IPlayer,
  ref,
): JSX.Element {
  const videoContainer = useRef<HTMLDivElement>()
  const internalVideoId = useRef<string | number>(videoId)
  const player = useRef<ITF1Player>()
  const hasPlayed = useRef<boolean>(false)
  const startsWithAutoplay = useRef<boolean>(autoplay !== undefined ? autoplay : true)
  const unMute = useRef<boolean>(true)
  const mediaStart = useRef(false)
  const reachedDurationTime = useRef(0)
  const lastUpdate = useRef(Date.now())
  const remainingQuartiles = useRef([...QUARTILES])
  const autoplayRetry = useRef<number>(MAX_RETRY_ON_AUTOPLAY_FAILURE)
  const playAlongTms =
    useRef<typeof TMS_PLAYALONG_FALSE | typeof TMS_PLAYALONG_AUTO | typeof TMS_PLAYALONG_MANUAL>(
      TMS_PLAYALONG_FALSE,
    )
  const [isPlayerInitialized, setIsPlayerInitialized] = useState(false)
  const { isTagCommanderReady, hit, videosData, playerData } = useContext(TagCommanderContext)
  const { loading: isAuthLoading, jwt, refreshToken } = useUser()

  const mediaInfoData = usePlayerMediaInfoListener({ player })

  useImperativeHandle(ref, () => ({ player: player.current, logEvent }))

  const startAt = 0
  const isLive = videoId?.toString().indexOf('L_') === 0
  // The video can start only if there is a videoId, consent has been given and no adblock detected
  const isPlayerSetupable =
    isTagCommanderReady && consent?.consentString && videoId && (!isAuthLoading || syndication)

  const logEvent = useCallback(
    (name: PlayerEvents, evt: PlayerEventData, isClickEvent?: boolean) => {
      // Temporary debug, needed to help building the true video event scenario
      console.log(
        // Warning: Ad hits are not managed here. See usePlayerAdsTMSHits
        `Debugging player events : ${name} (${mediaStart.current ? 'MEDIA' : 'PUB'})`,
        evt,
      )

      // Generate data and hit only if event is known, media is playing and tms video are available
      if (
        !!EVENTS_MAPPING[name] &&
        // Don't send event if the video is not started (except for click event)
        (mediaStart.current || name === CLICK) &&
        videosData?.[internalVideoId.current]
      ) {
        const eventName: PlayerEvents = name === COMINGNEXT_NEXT_CLICK ? END : name
        const tmsEventName: ValueOf<typeof EVENTS_MAPPING> = EVENTS_MAPPING[eventName]
        const { content_video_duration, content_video_videoReachedDuration } =
          videosData[internalVideoId.current]
        // Define videoReachedDurationTime
        let videoReachedDurationTime = reachedDurationTime.current

        if (isLive || name === START) {
          videoReachedDurationTime = 0
        }
        if (name === END) {
          videoReachedDurationTime =
            mediaInfoData?.content_video_duration ||
            videosData[internalVideoId.current]?.content_video_duration
        }
        if ([SEEK, SEEKED].includes(name)) {
          videoReachedDurationTime = Number(evt.value)
        }

        if (evt?.value === TMS_BUTTON_MUTE) {
          unMute.current = false
        }

        if (evt?.value === TMS_BUTTON_UNMUTE) {
          unMute.current = true
        }

        const playerPosition = getPlayerPosition(videoContainer.current)

        const currentVideoData: {
          [key: string]: string | number
        } = {
          ...videosData[internalVideoId.current],
          ...mediaInfoData,
          ...(name === CLICK ? { screen_clickableElementName: evt.value } : {}),
          id: tmsEventName,
          content_video_action: ACTIONS_MAPPING[eventName],
          content_video_duration: content_video_duration,
          content_video_videoReachedDurationTime: videoReachedDurationTime,
          content_video_playAlong: playAlongTms.current,
          player_playAuto: String(startsWithAutoplay.current),
          player_playSoundOn: String(unMute.current),
          content_video_videoReachedDuration: content_video_videoReachedDuration,
          user_consentState: consent?.consentPolicyState,
          user_consentString: consent?.consentString,
          player_playPosition: playerPosition > 0 ? String(playerPosition) : '',
          ...(evt?.event ? evt.event : {}),
        }

        // content_video_videoReachedDuration parameter only on start, end and quartile event
        if ([START, END, QUARTILE].includes(name)) {
          const reachedDuration =
            isLive || name === START ? 0 : name === QUARTILE ? evt.currentQuartile : 100
          currentVideoData.content_video_videoReachedDuration = reachedDuration
        }

        // Send hit only if tms values are available
        hit(propertiesToString(removeEmptyProperties(currentVideoData)), {
          isClickEvent,
        })
      }
      // Change playAlongTms params after every video change
      if (END === name) {
        // isDurationEnd : the last video is complete finish
        const isDurationEnd =
          reachedDurationTime.current + 2 >=
          videosData[internalVideoId.current]?.content_video_duration
        //Don't change playAlongTms if have an introduction video
        if (introductionVideoId !== internalVideoId.current) {
          playAlongTms.current = isDurationEnd ? TMS_PLAYALONG_AUTO : TMS_PLAYALONG_MANUAL
        }
      }
    },
    [hit, videosData, consent?.consentPolicyState, consent?.consentString],
  )
  const logEnd = useCallback(() => mediaStart.current && logEvent(END, null), [logEvent])

  usePlayerAdsTMSHits({
    isPlayerInitialized,
    player,
    videoId,
    videosData,
    tms: {
      playAlongTms: playAlongTms.current,
      startsWithAutoplay: startsWithAutoplay,
      unMute: unMute,
      getPlayerPosition: () => {
        const position = getPlayerPosition(videoContainer.current)
        return position > 0 ? position : undefined
      },
    },
  })

  usePlayerCTATMSHits({
    player,
    isPlayerInitialized,
    logEvent,
  })

  useBeforeOnLoad(logEnd, isPlayerInitialized)

  const handleQuartiles = useCallback(
    (evt: PlayerEventData) => {
      const duration = videosData[internalVideoId.current]?.content_video_duration || 0
      if (!mediaStart.current || isLive || !duration || !remainingQuartiles.current.length) {
        return
      }

      if (
        reachedDurationTime.current > Math.floor((duration * remainingQuartiles.current[0]) / 100)
      ) {
        evt.currentQuartile = remainingQuartiles.current.shift()
        logEvent(QUARTILE, evt)
      }
    },
    [videosData, isLive],
  )

  const handlePlayWithClick = useCallback(() => {
    startsWithAutoplay.current = false
  }, [])
  const handlePlayEvent = useCallback(
    (evt: PlayerEventData) => {
      if (!hasPlayed.current && !startsWithAutoplay.current) {
        player?.current?.volume(0.5)
      }
      hasPlayed.current = true
      logEvent(PLAY, evt)
    },
    [logEvent],
  )
  const handlePauseEvent = useCallback((evt: PlayerEventData) => logEvent(PAUSE, evt), [logEvent])
  const handleEndEvent = useCallback(
    (evt: PlayerEventData) => {
      logEvent(END, evt)
      mediaStart.current = false
      if (onMediaEnd) onMediaEnd()
    },
    [logEvent],
  )
  const handleNextEvent = useCallback(() => {
    playAlongTms.current = TMS_PLAYALONG_MANUAL
  }, [])

  const handleStartEvent = useCallback(
    (evt: PlayerEventData) => {
      // Beware. Tricky implementation to simulate missing "start" event from player
      // Waiting for second event "timeupdate" that send the media position because live media send one during ads
      if (reachedDurationTime.current !== 0 && !mediaStart.current) {
        mediaStart.current = true
        logEvent(START, evt)
      }

      reachedDurationTime.current = Math.floor(Number(evt.value)) || 0

      // https://e-tf1.atlassian.net/browse/LCI-8421 updating this
      // time variable to help eStat with regular progress event
      const now = Date.now()
      if (now - lastUpdate.current > TIME_BETWEEN_UPDATES) {
        window.tc_vars.content_video_videoReachedDurationTime = reachedDurationTime.current
        lastUpdate.current = now
      }

      handleQuartiles(evt)
    },
    [logEvent, handleQuartiles],
  )

  const handleSeeked = useCallback(
    (evt: { value: { from: number; to: number } }) => {
      const from = Math.trunc(evt.value.from)
      const to = Math.trunc(evt.value.to)
      logEvent(SEEK, { value: from })
      logEvent(SEEKED, { value: to })
      const SEEK_FORWARD_OR_BACK = to > from ? TMS_SEEK_FORWARD : TMS_SEEK_BACKWARD
      logEvent(CLICK, { value: SEEK_FORWARD_OR_BACK }, true)
    },
    [logEvent],
  )

  const handleAutoPlayFailed = useCallback(() => {
    if (autoplayRetry.current && autoplayRetry.current > 0) {
      unMute.current = false
      autoplayRetry.current--

      player.current?.mute()
      player.current?.play()
    }
  }, [videoId, startAt, autoplay, autoplayMuted])

  const handleJWTExpired = useCallback(() => {
    refreshToken()
  }, [])

  useEffect(() => {
    if (jwt) {
      player.current?.setSecurityToken(jwt)
    }
  }, [jwt])

  const handlePlaying = useCallback(
    function handlePlayingCallback() {
      onMediaPlay?.()
    },
    [onMediaPlay],
  )

  const setupTMSListener = useCallback(() => {
    player.current?.on?.(PLAY, handlePlayEvent)
    player.current?.on?.(PLAYING, handlePlaying)
    player.current?.on?.(PAUSE, handlePauseEvent)
    player.current?.on?.(START, handleStartEvent)
    player.current?.on?.(END, handleEndEvent)
    player.current?.on?.(AUTOPLAY_FAILED, handleAutoPlayFailed)
    player.current?.on?.(COMINGNEXT_NEXT_CLICK, handleNextEvent)
    player.current?.on?.(SEEKED, handleSeeked)
    player.current?.on?.(PLAY_ON_MEDIA_CLICK, handlePlayWithClick)
    player.current?.on?.(JWT_EXPIRED, handleJWTExpired)
  }, [
    handlePlayEvent,
    handlePlaying,
    handlePauseEvent,
    handleStartEvent,
    handleEndEvent,
    handleAutoPlayFailed,
    handleNextEvent,
    handleSeeked,
    handlePlayWithClick,
    handleJWTExpired,
  ])

  const destroyTMSPlayer = useCallback(() => {
    player.current?.off?.(PLAY, handlePlayEvent)
    player.current?.off?.(PLAYING, handlePlaying)
    player.current?.off?.(PAUSE, handlePauseEvent)
    player.current?.off?.(START, handleStartEvent)
    player.current?.off?.(END, handleEndEvent)
    player.current?.off?.(AUTOPLAY_FAILED, handleAutoPlayFailed)
    player.current?.off?.(COMINGNEXT_NEXT_CLICK, handleNextEvent)
    player.current?.off?.(SEEKED, handleSeeked)
    player.current?.off?.(PLAY_ON_MEDIA_CLICK, handlePlayWithClick)
    player.current?.off?.(JWT_EXPIRED, handleJWTExpired)
  }, [
    handlePlayEvent,
    handlePlaying,
    handlePauseEvent,
    handleStartEvent,
    handleEndEvent,
    handleAutoPlayFailed,
    handleNextEvent,
    handleSeeked,
    handlePlayWithClick,
    handleJWTExpired,
  ])

  async function initPlayer() {
    const conf: PlayerConfig = getPlayerConfig({
      videoRef: videoContainer.current,
      comingNext: hasComingNext,
      consentString: consent?.consentString,
      noAds: noAds,
      tmsPlayerData: playerData,
      isLive,
      syndication,
      jwt,
      disabledOptionsButton,
    })

    player.current = new TF1Player(conf)

    setIsPlayerInitialized(true)
    onPlayerInit && onPlayerInit()
    unMute.current = !autoplayMuted

    await player.current.init()
    await player.current.loadMedia(videoId, startAt, {
      media: {
        mediaId: videoId,
        autoplay: autoplay,
        autoplayMuted: autoplayMuted,
        mediaInfoParams: mediaInfoParams || {},
        playbackTimeOffset: liveTimeShift || 0,
      },
    })

    onPlayerReady && onPlayerReady()
  }

  async function nextMedia() {
    if (mediaStart.current) {
      logEvent(END, null)
    }
    // Reset video current status when loading a new media
    mediaStart.current = false
    unMute.current = false
    reachedDurationTime.current = 0
    remainingQuartiles.current = [...QUARTILES]

    await player.current.loadMedia(videoId, startAt, {
      media: {
        mediaId: videoId,
        autoplay: autoplay,
        autoplayMuted: false,
        playbackMode: 'continuousPlay',
        mediaInfoParams: mediaInfoParams || {},
        playbackTimeOffset: liveTimeShift || 0,
      },
    })
    internalVideoId.current = videoId
    onVideoChange?.()
  }

  useEffect(() => {
    setupTMSListener()

    return () => {
      destroyTMSPlayer()
    }
  }, [setupTMSListener, destroyTMSPlayer, isPlayerInitialized])

  useEffect(() => {
    if (isPlayerSetupable) {
      !player.current ? initPlayer() : nextMedia()
    }
  }, [isPlayerSetupable, videoId])

  useEffect(() => {
    if (isIOS() && isPlayerInitialized) {
      // Hack for player to make fullscreen and other features working on iOS
      const listeners = getAllPlayerListeners()
      listeners.forEach((listener) => {
        player.current?.on?.(listener, defaultListenerCallback)
      })

      return function clean() {
        const listeners = getAllPlayerListeners()
        listeners.forEach((listener) => {
          player.current?.off?.(listener, defaultListenerCallback)
        })
      }
    }
  }, [isPlayerInitialized])

  return (
    <>
      {children}
      <div ref={videoContainer} className="Player" id={playerId} />
      <style jsx>{`
        .Player {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: black;
        }
      `}</style>
    </>
  )
}

export const Player = forwardRef<any, IPlayer>(PlayerComponent)
