import { useEffect, useRef } from 'react'
import slugify from 'slugify'
import { MEDIA_INFO } from '../constants/playerEvents'

interface MediaInfoData {
  content_video_videoName?: string
  program_broadcastingChannel?: string
  content_video_videoType?: string
  content_video_videoEmNumber?: string
  content_video_duration?: string
  program_programName?: string
  program_linkingChannel?: string
}

/**
 *
 * @description This hook listens to the media info player event and returns the media info data needed for TMS hits
 */
export function usePlayerMediaInfoListener({ player }): MediaInfoData {
  const mediaInfoData = useRef<MediaInfoData>({})

  function handleMediaInfoEvent({ value: { mediainfo } }) {
    if (mediainfo?.media?.type === 'live' || mediainfo?.media?.type === 'stream') {
      mediaInfoData.current = {
        content_video_videoName: slugify(mediainfo?.content?.title, {
          lower: true,
        }),
        program_broadcastingChannel: slugify(mediainfo?.media?.channel, {
          lower: true,
        }),
        content_video_videoType: mediainfo?.media?.type === 'live' ? 'live' : 'replay',
        content_video_videoEmNumber: slugify(mediainfo?.content?.emId, {
          lower: true,
        }),
        content_video_duration: String(
          mediainfo?.media?.duration ??
            (mediainfo?.media?.endDate &&
              mediainfo?.media?.startDate &&
              String(
                (new Date(mediainfo?.media?.endDate).getTime() -
                  new Date(mediainfo?.media?.startDate).getTime()) /
                  1000,
              )) ??
            '0',
        ),
        program_programName: slugify(mediainfo?.mediametrie?.chapters[0]?.estatS2, {
          lower: true,
        }),
        program_linkingChannel: slugify(mediainfo?.media?.attach, { lower: true }),
      }
    } else {
      if (mediainfo?.media?.title) {
        mediaInfoData.current = {
          content_video_videoName: slugify(mediainfo.media.title, { lower: true }),
        }
      }
    }
  }

  useEffect(
    function handleMediaInfoListener() {
      player.current?.on(MEDIA_INFO, handleMediaInfoEvent)
      return function cleanup() {
        player.current?.off(MEDIA_INFO, handleMediaInfoEvent)
      }
    },
    [player],
  )

  return mediaInfoData.current
}
